import wretch from 'wretch';
import { constants } from './constants';

let authToken = '';

function redirectToLogin() {
  if (window.location.pathname !== '/login') window.location.replace('/login');
}

const serverAPI = wretch()
  .url(constants.serverURL)
  .resolve((_) => _.unauthorized(() => redirectToLogin()));

function setAPIAuthToken(accessToken: string) {
  authToken = `Bearer ${accessToken}`;
}

function clearAPIAuthToken() {
  authToken = '';
}

const getServerAPI = () => serverAPI.auth(authToken);

export { getServerAPI, setAPIAuthToken, clearAPIAuthToken };
