import { getLocalStorage, LocalStorageKeys } from './localstorage';

interface Environment {
  appName: string;
  nodeEnv: typeof process.env.NODE_ENV;
  mixpanelToken: string;
  razorpayKey: string;
  theme: string;
  viewerUrl: string;
  azure: {
    account: string;
    sasUrl: string;
  };
  demousers: {
    customer: {
      username: string;
      password: string;
    };
    teleradiologyCompany: {
      username: string;
      password: string;
    };
    radiologist: {
      username: string;
      password: string;
    };
  };
}

const environment: Environment = {
  appName: process.env.REACT_APP_APP_NAME || 'RadSmart',
  nodeEnv: process.env.NODE_ENV,
  mixpanelToken: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '',
  razorpayKey: process.env.REACT_APP_RAZOR_PAY_KEY || '',
  theme: getLocalStorage(LocalStorageKeys.DARK_MODE) || 'light',
  viewerUrl: process.env.REACT_APP_VIEWER_URL || 'https://viewer.radsmart.in',
  azure: {
    account: process.env.REACT_APP_AZURE_ACCOUNT || 'dummy',
    sasUrl: process.env.REACT_APP_AZURE_SAS_URL || 'dummy',
  },
  demousers: {
    customer: {
      username: process.env.REACT_APP_CUSTOMER_USER_USERNAME || '',
      password: process.env.REACT_APP_CUSTOMER_USER_PASSWORD || '',
    },
    teleradiologyCompany: {
      username: process.env.REACT_APP_TELERADIOLOGYCOMPANY_USER_USERNAME || '',
      password: process.env.REACT_APP_TELERADIOLOGYCOMPANY_USER_PASSWORD || '',
    },
    radiologist: {
      username: process.env.REACT_APP_RADIOLOGIST_USER_USERNAME || '',
      password: process.env.REACT_APP_RADIOLOGIST_USER_PASSWORD || '',
    },
  },
};

const isDev = () => environment.nodeEnv === 'development';

const isTelefi = () => environment.appName === 'TeleFi.ai';
const getAppName = () => environment.appName;

const isElectron = () => {
  // Renderer process
  if (
    typeof window !== 'undefined' &&
    typeof window.process === 'object' &&
    (window.process as any).type === 'renderer'
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== 'undefined' &&
    typeof process.versions === 'object' &&
    !!(process.versions as any).electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to false
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
};

export { environment, isDev, isElectron, getAppName, isTelefi };
