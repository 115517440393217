import * as MetricProcessor from './metrics-processor';

/** ***************************************************************
 * EVENT TYPES
 **************************************************************** */

import {
  BulkStudyReportTaskCreateResponse,
  BulkStudyReportTaskInput,
  DraftStudyReportTask,
  StudyReportTask,
  StudyReportTaskInput,
} from '../api-types';
import { ArrayElement } from '../typescript-utils';

/**
 * Metrics events
 * IMPORTANT: Ensure that the values of the events follow the following
 * convention
 * 1. Begin with the name of the flow / entity
 * 2. All words to begin with Capital letters
 */
enum AnalyticsEventEnum {
  // Authentication Related events
  USER__LOGGED_IN = 'User Login',
  USER__LOGGED_OUT = 'User Logout',

  // Study Report Task (Also DraftStudyReportTask)
  XRAYSCAN__UPLOAD = 'Xray Scan Uploaded',
  XRAYSCAN__EDIT = 'Xray Scan Details Edited',
  XRAYSCAN__DELETE = 'Xray Scan Deleted',
  XRAYSCAN__RADIOLOGIST_ASSIGNED = 'Xray Scan Radiologist Assigned',
  XRAYSCAN__REPORT_DOWNLOAD = 'Xray Scan Report Downloaded',

  //  Event related to Radiologist Reporting Uploaded Scan
  XRAYSCAN__REPORT_UPDATE = 'Xray Scan Report Updated',
  XRAYSCAN__REPORT_DELETE = 'Xray Scan Report Deleted',
  XRAYSCAN__REPORT_SUBMIT = 'Xray Scan Report Submitted',
  XRAYSCAN__REPORT_SAVE = 'Xray Scan Report Saved',

  // Events related to Customer Support
  CUSTOMER_SUPPORT_ISSUE_CREATED = 'Customer Support Issue Created',

  // Events related to Report share
  REPORT_SHARE = 'Report Share',
}

interface UserLoggedIn {
  type: AnalyticsEventEnum.USER__LOGGED_IN;
  payload: {
    type: MetricProcessor.UserLoggedIn['payload']['Login Type'];
  };
}

interface UserLoggedOut {
  type: AnalyticsEventEnum.USER__LOGGED_OUT;
}

interface XrayScanUpload {
  type: AnalyticsEventEnum.XRAYSCAN__UPLOAD;
  payload: {
    type: 'BULK' | 'NORMAL';
    source: ArrayElement<MetricProcessor.XrayScanUpload['payload']>['Upload Scan Source'];
    studyReportTask: StudyReportTaskInput | BulkStudyReportTaskInput;
    response: StudyReportTask | BulkStudyReportTaskCreateResponse;
    uploadStartTime: Date;
  };
}

interface XrayScanAssignedRadiologist {
  type: AnalyticsEventEnum.XRAYSCAN__RADIOLOGIST_ASSIGNED;
  payload: {
    radiologistId: number;
    studyReportTasks: StudyReportTask[];
  };
}

interface XrayScanDelete {
  type: AnalyticsEventEnum.XRAYSCAN__DELETE;
  payload: {
    studyReportTask: StudyReportTask | DraftStudyReportTask;
    scanType: 'Normal' | 'Draft';
  };
}

interface XrayScanUpdate {
  type: AnalyticsEventEnum.XRAYSCAN__EDIT;
  payload: {
    studyReportTask: StudyReportTask;
  };
}

interface XrayScanReportDownload {
  type: AnalyticsEventEnum.XRAYSCAN__REPORT_DOWNLOAD;
  payload: {
    studyReportTask: StudyReportTask;
  };
}

interface XrayScanReportSave {
  type: AnalyticsEventEnum.XRAYSCAN__REPORT_SAVE;
  payload: {
    studyReportTask: StudyReportTask;
  };
}

interface XrayStudyReportSubmit {
  type: AnalyticsEventEnum.XRAYSCAN__REPORT_SUBMIT;
  payload: {
    studyReportTask: StudyReportTask;
  };
}

interface CustomerSupportIssueCreated {
  type: AnalyticsEventEnum.CUSTOMER_SUPPORT_ISSUE_CREATED;
  payload: {
    studyReportTask: StudyReportTask;
    issueType: string;
  };
}

interface ReportShare {
  type: AnalyticsEventEnum.REPORT_SHARE;
  payload: {
    studyReportTaskId: string;
    phone: string;
    contactType: number;
  };
}

type AnalyticsEvent =
  | UserLoggedIn
  | UserLoggedOut
  | XrayScanDelete
  | XrayScanReportDownload
  | XrayScanUpload
  | XrayScanUpdate
  | XrayScanAssignedRadiologist
  | XrayStudyReportSubmit
  | XrayScanReportSave
  | CustomerSupportIssueCreated
  | ReportShare;

export type { AnalyticsEvent };
export { AnalyticsEventEnum };
