enum LocalStorageKeys {
  AUTH_TOKEN = 'AUTH_TOKEN',
  SELECTED_CENTER = 'SELECTED_CENTER',
  DARK_MODE = 'DARK_MODE',
}

function setLocalStorage(key: LocalStorageKeys, value: string) {
  localStorage.setItem(key, value);
}

function getLocalStorage(key: LocalStorageKeys): string | null {
  return localStorage.getItem(key);
}

function removeLocalStorage(key: LocalStorageKeys) {
  localStorage.removeItem(key);
}

export { setLocalStorage, getLocalStorage, removeLocalStorage, LocalStorageKeys };
