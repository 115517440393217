/* eslint-disable camelcase */

import { BodyPart, ScanView } from '../services/organ-view-logic/organ-view-service';

/** **********************************************************
 *                       GENERIC TYPES
 *********************************************************** */
export interface PaginatedResponse<T> {
  count: number;
  results: T[];
}

export interface CursorPaginatedResponse<T> {
  next?: string;
  previous?: string;
  results: T[];
}

export function getCursorFromLink(link?: string): string | undefined {
  if (!link) return undefined;

  const paramsStr = link.split('?')[1];
  const urlParams = new URLSearchParams(paramsStr);
  const params = Object.fromEntries(urlParams.entries());
  return params?.cursor;
}

/** ******************************************
 * User and Group
 ******************************************* */

export interface Group {
  name:
    | 'RadiologyCompany'
    | 'RadiologyCompanyManager'
    | 'Customer'
    | 'CustomerCenter'
    | 'Radiologist';
}

export interface User {
  id: string;
  name: string;
  username: string;
}

export interface UserDetail {
  id: string;
  username: string;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  date_joined: string;
  last_login: string;
  groups: Group[];
  centers: CustomerCenter[];
  customer?: Customer;
  userprofile?: {
    prefix?: string;
    phone_no?: string;
  };
}

/** ******************************************
 * Radiologist
 ******************************************* */
export interface RadiologistDetail {
  designation: string;
  reg_no: string;
  profile_picture?: string;
  user: User;
}

export interface RadiologistProfile {
  company: number;
  signature: string | File;
  designation: string;
  reg_no: string;
  payment_type: PaymentType;
  cost1: number;
  cost2: number;
  special_view_cost: number;
}

export type RadiologistProfileUpload = Omit<RadiologistProfile, 'signature'>;

export enum PaymentType {
  HOURLY = 1,
  PER_REPORT = 2,
}

export const PaymentTypeDisplayMap: Record<PaymentType, string> = {
  [PaymentType.HOURLY]: 'Hourly',
  [PaymentType.PER_REPORT]: 'Per Report',
};

export interface RadiologistSummary {
  radiologist: RadiologistProfile;
  schedules: RadiologistWeeklySchedule;
  user: User;
}

/** ******************************************
 * Customer
 ******************************************* */
export interface Customer {
  user_id: string;
  phone: string;
  address: string;
  report_desc: boolean;
  enable_patient_id: boolean;
}

export interface CustomerCenter {
  id: string;
  name: string;
  report_desc: boolean;
  enable_patient_id: boolean;
}

export interface CustomerCenterListItem {
  id: string;
  name: string;
  customer_id: number;
  user_id?: number;
}

export interface CustomerCenterForRadiologist {
  id: string;
  report_desc: boolean;
  radiologist_info: string | null;
}

export interface CenterInfo {
  id: string;
  name: string;
  phone: string;
  customerName: string;
  radiologist_info?: string;
}

export interface RadiologyCompanyContact {
  name?: string;
  contact_info?: string;
}

export interface CustomerSummary {
  id: number;
  username: string;
}

/** ******************************************
 * Blob
 ******************************************* */

export interface Blob {
  id: string;
  original_name?: string;
  object: string;
  container: string;
  url?: string;
  account: string;
}

/** ******************************************
 * Study, Series, Patient
 ******************************************* */

export enum PatientGender {
  MALE = 1,
  FEMALE = 2,
  THIRD = 3,
}

export type PatientGenderSting = 'M' | 'F' | '3rd';

export const PatientGenderMap: Record<PatientGender, PatientGenderSting> = {
  [PatientGender.MALE]: 'M',
  [PatientGender.FEMALE]: 'F',
  [PatientGender.THIRD]: '3rd',
};

export interface Patient {
  id: string;
  patient_id?: string;
  name: string;
  gender: PatientGender;
  age: number;
  age_month?: number;
  age_days?: number;
}

export interface OrganViewSeriesType {
  organ: BodyPart;
  view?: ScanView[];
}

export interface Series {
  id: string;
  uid?: string;
  description: string | OrganViewSeriesType;
  img_count: number;
  images: Blob[];
  thumbnail?: Blob | null;
}

export enum Modality {
  CR = 'CR',
  CT = 'CT',
  MR = 'MR',
  US = 'US',
  OT = 'OT',
  BI = 'BI',
  CD = 'CD',
  DD = 'DD',
  DG = 'DG',
  ES = 'ES',
  LS = 'LS',
  PT = 'PT',
  RG = 'RG',
  ST = 'ST',
  TG = 'TG',
  XA = 'XA',
  RF = 'RF',
  RTIMAGE = 'RTIMAGE',
  RTDOSE = 'RTDOSE',
  RTSTRUCT = 'RTSTRUCT',
  RTPLAN = 'RTPLAN',
  RTRECORD = 'RTRECORD',
  HC = 'HC',
  DX = 'DX',
  NM = 'NM',
  MG = 'MG',
  IO = 'IO',
  PX = 'PX',
  GM = 'GM',
  SM = 'SM',
  XC = 'XC',
  PR = 'PR',
  AU = 'AU',
  EPS = 'EPS',
  HD = 'HD',
  SR = 'SR',
  IVUS = 'IVUS',
  OP = 'OP',
  SMR = 'SMR',
}

let MODALITY_LIST: string[] | undefined;

export function getAllModalityList(): string[] {
  if (MODALITY_LIST !== undefined) return MODALITY_LIST;

  MODALITY_LIST = [];
  Object.keys(Modality).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MODALITY_LIST.push(key);
  });
  return MODALITY_LIST;
}

export interface Study {
  id: string;
  uid?: string;
  accession_no?: string;
  description: string;
  modality: Modality;
  attatchments: Blob[];
  comments: string;
  study_date: string;
  referring_physician?: string;
  patient: Patient;
  center: CustomerCenter;
  series: Series[];
  data_blob?: Blob;
}

export type StudyForRadiologist = Study & {
  center: CustomerCenterForRadiologist;
};

/** ******************************************
 * Study Report
 ******************************************* */

export interface StudyReportContent {
  title: string;
  description: string;
  findings: string;
  impressions: string;
  completeInfo: string;
}
export interface StudyReport {
  id: string;
  report?: Blob;
  report_content?: StudyReportContent;
  downloaded: boolean;
}

export interface AiPredictions {
  status: string;
  message: string;
  data?: {
    classes: {
      abnormal: number;
      cardiomegaly: number;
      devices: number;
      diaphragmatic_abnormalities: number;
      lung_opacification: number;
      nodular: number;
      others: number;
      pleural_effusion: number;
      pleural_other: number;
    };
    probabs: {
      abnormal: number;
      cardiomegaly: number;
      devices: number;
      diaphragmatic_abnormalities: number;
      lung_opacification: number;
      nodular: number;
      others: number;
      pleural_effusion: number;
      pleural_other: number;
    };
    report?: string;
    impressions?: string;
  };
}
/** ******************************************
 * Study Report Task
 ******************************************* */

export interface ReportDownloadTemplate {
  id: string;
  name: string;
  templates: string;
}

export enum StudyReportTaskStatus {
  OPEN = 1,
  ASSIGNED = 2,
  DRAFT = 3,
  COMPLETED = 4,
  ISSUE = 5,
  IP_VERIFY = 6,
}

/**
 * Map of StudyReportTaskStatus to display text, color
 */
export const StudyReportTaskStatsMap: Record<
  StudyReportTaskStatus,
  [string, string | undefined]
> = {
  [StudyReportTaskStatus.OPEN]: ['OPEN', 'gold'],
  [StudyReportTaskStatus.IP_VERIFY]: ['VERIFY', 'purple'],
  [StudyReportTaskStatus.ASSIGNED]: ['ASN', 'orange'],
  [StudyReportTaskStatus.DRAFT]: ['DRAFT', undefined],
  // [StudyReportTaskStatus.OP_VERIFY]: ['VERIFY', 'orange'],
  [StudyReportTaskStatus.COMPLETED]: ['DONE', 'green'],
  [StudyReportTaskStatus.ISSUE]: ['ISSUE', 'red'],
};

export const StudyReportTaskStatsShortMap: Record<
  StudyReportTaskStatus,
  [string, string | undefined]
> = {
  [StudyReportTaskStatus.OPEN]: ['O', 'gold'],
  [StudyReportTaskStatus.IP_VERIFY]: ['V', 'purple'],
  [StudyReportTaskStatus.ASSIGNED]: ['A', 'orange'],
  [StudyReportTaskStatus.DRAFT]: ['D', undefined],
  // [StudyReportTaskStatus.OP_VERIFY]: ['OV', undefined],
  [StudyReportTaskStatus.COMPLETED]: ['C', 'green'],
  [StudyReportTaskStatus.ISSUE]: ['I', 'red'],
};

/**
 * Map of StudyReportTaskStatus to display text, color for Customer
 */
export const StudyReportTaskStatsMapCustomer: Record<
  StudyReportTaskStatus,
  [string, string | undefined]
> = {
  [StudyReportTaskStatus.OPEN]: ['Waiting', undefined],
  [StudyReportTaskStatus.IP_VERIFY]: ['Waiting', undefined],
  [StudyReportTaskStatus.ASSIGNED]: ['Waiting', undefined],
  [StudyReportTaskStatus.DRAFT]: ['Waiting', undefined],
  // [StudyReportTaskStatus.OP_VERIFY]: ['Waiting', undefined],
  [StudyReportTaskStatus.COMPLETED]: ['DONE', 'green'],
  [StudyReportTaskStatus.ISSUE]: ['ISSUE', 'red'],
};

export enum StudyReportTaskPriority {
  NORMAL = 1,
  HIGH = 2,
}

export enum StudyReportTaskBatchType {
  BATCH_START = 1,
  BATCH_MIDDLE = 2,
  BATCH_END = 3,
}

export interface StudyReportTask {
  id: string;
  center: CustomerCenter;
  study: Study;
  status: StudyReportTaskStatus;
  priority: StudyReportTaskPriority;
  reports: StudyReport[];
  report_templates: ReportDownloadTemplate[];
  is_portable: boolean;
  batch_type: StudyReportTaskBatchType;
  batch_size: number;
  batch_leader?: string;
  thread?: number;
  created_at: string;
  completed_at?: string;
}

export interface StudyReportTaskShareInput {
  task: string;
  template: string;
  phone_no: string;
  contact_type: number;
  prefix: string;
}

export interface StudyReportTaskShareDetails {
  share_count: number;
  max_share_count: number;
  msg_patient: string;
  msg_referrer: string;
}

export interface StudyReportTaskUpdateVal {
  patientId: string; // Equaivalent of patient.id (server generated id)
  patient_id?: string;
  patient_name: string;
  age: number;
  age_month?: number;
  age_days?: number;
  gender: PatientGender;
  study_date: string;
  referring_physician?: string;
  is_portable?: boolean;
  priority: StudyReportTaskPriority;
  comments: string;
  attatchments: Blob[];
  report_data: {
    [id: string]: {
      title: string;
      desc: string;
    };
  };
}

export interface StudyReportTaskDetail extends StudyReportTask {
  radiologist?: User;
  auto_assign_rad?: User;
}

export type StudyReportTaskForRadiologist = StudyReportTask & { study: StudyForRadiologist };

export interface StudyReportTaskInput {
  id?: string;
  center: {
    id: string;
    name: string;
  };
  priority: StudyReportTaskPriority;
  is_portable: boolean;
  study: {
    id?: string;
    uid?: string;
    accession_no?: string;
    description: string;
    modality: Modality;
    data_blob?: Blob;
    comments?: string;
    attatchments?: Omit<Blob, 'id'>[];
    center: {
      name: string;
    };
    patient: {
      id?: string;
      patient_id?: string;
      name: string;
      gender: PatientGender;
      age: number;
      age_month?: number;
      age_days?: number;
    };
    referring_physician?: string;
    study_date: string;
    series: {
      images: Omit<Blob, 'id'>[];
      thumbnail?: Omit<Blob, 'id'>;
      uid?: string;
      img_count?: number;
      description: string;
    }[];
  };
}

export interface BulkStudyReportTaskInput {
  center: {
    id: string;
    name: string;
  };
  priority: StudyReportTaskPriority;
  is_portable: boolean;
  patient: {
    id?: string;
    patient_id?: string;
    name: string;
    gender: PatientGender;
    age: number;
    age_month?: number;
    age_days?: number;
  };
  study: {
    referring_physician?: string;
    description: string;
    comments?: string;
    attatchments?: Omit<Blob, 'id'>[];
  };
  series: {
    images: Omit<Blob, 'id'>[];
    description: string;
    study_date: string;
  }[];
}

export type BulkStudyReportTaskCreateResponse = { id: string; images: number }[];

/** ******************************************
 * Draft Study Report Task
 ******************************************* */

export interface DraftSeries {
  series_id: string;
  img_count: number;
  description?: string;
  preview_images: Blob[];
  dcm_images: Blob[] | null;
}

export enum DraftStudyReportTaskStatus {
  DRAFT = 1,
  SUBMITTED = 2,
  PROCESSING = 3,
  PROCESS_ERROR = 4,
}

export interface DraftStudyReportTask {
  id: string;
  center: CustomerCenter;
  patient_id: string;
  name: string;
  gender: PatientGender;
  age: number;
  modality: Modality;
  description: string;
  comments: string;
  data_blob?: Blob;
  accession_no?: string;
  referring_physician: string;
  study_date: string;
  study_time: string;
  series: DraftSeries[];
  created_at: string;
  modified_at: string;
  status: DraftStudyReportTaskStatus;
}

/** ******************************************
 * Radiologist Template
 ******************************************* */

export interface RadiologistTemplateSparse {
  id: string;
  name: string;
  modality: Modality;
  radiologist?: number;
}
export interface RadiologistTemplate {
  id: string;
  title: string;
  name: string;
  description?: string;
  findings: string;
  impressions: string;
  modality: Modality;
  radiologist?: number;
  radiology_company?: number;  
  completeInfo?: string;
}

export interface RadiologistTemplateInput {
  title: string;
  name: string;
  description?: string;
  findings: string;
  impressions: string;
  modality: Modality;
  completeInfo: string;
}

/** ******************************************
 * Radiologist Status API for TeleradiologyCompnay
 ******************************************* */

export interface RadiologistStatusInfo {
  // Map of radiologist user id to task count
  rad_task_count: {
    [key: string]: number;
  };
  // Map of radiologist user id to task id
  rad_current_task: {
    [key: string]: number | null;
  };

  // List of online radiologists
  online_rads: User[];
}

/** ******************************************
 * Billing
 ******************************************* */

export enum Currency {
  RUPEES = 0,
  DOLLARS = 1,
}

export const CurrencyMap: Record<Currency, string> = {
  [Currency.RUPEES]: '₹',
  [Currency.DOLLARS]: '$',
};

export enum BillStatus {
  UNPAID = 0,
  PAID = 1,
  FAILED = 2,
}

export enum BillRemittance {
  INWARD = 0,
  OUTWARD = 1,
}

export const BillStatusMap: Record<BillStatus, string> = {
  [BillStatus.UNPAID]: 'Unpaid',
  [BillStatus.PAID]: 'Paid',
  [BillStatus.FAILED]: 'Failed',
};

export interface BillItem {
  id: string;
  description: string;
  amount: number;
}

export interface Bill {
  id: string;
  bill_from_date: string;
  bill_to_date: string;
  description: string;
  remittance: BillRemittance;
  amount: number;
  currency: Currency;
  status: BillStatus;
  attachments: Blob[];
  created_at: string;
  modified_at: string;
  bill_items: BillItem[];
}

export interface BillPaymentData {
  id: string;
  payment_data: Record<string, any>;
  bill: number;
}

/** ******************************************
 * Notifications
 ******************************************* */

export enum NotificationType {
  GLOBAL = 1,
  NORMAL = 2,
}

export enum NotificationSeverity {
  INFO = 1,
  NORMAL = 2,
  HIGH = 3,
  CRITICAL = 4,
}

export enum NotificationStatus {
  CREATED = 1,
  DELIVERED = 2,
  READ = 3,
}

export enum NotificationActionType {
  REFRESH = 1,
  LINK = 2,
  FUNCTION = 3,
}

interface NotificationActionLink {
  type: NotificationActionType.LINK;
  data: {
    link: string;
  };
}

interface NotificationActionRefresh {
  type: NotificationActionType.REFRESH;
}

interface NotificationActionFunction {
  type: NotificationActionType.FUNCTION;
  data: {
    fn: () => void;
  };
}

type NotificationActionPayload =
  | NotificationActionLink
  | NotificationActionRefresh
  | NotificationActionFunction;

export interface NotificationAction {
  name: string;
  payload: NotificationActionPayload;
}

export interface Notification {
  id: string;
  type: NotificationType;
  isLocal?: boolean;
  severity: NotificationSeverity;
  title?: string;
  content: string;
  actions?: NotificationAction[];
  click_action?: NotificationAction;
  custom_image_url?: string;
  status: NotificationStatus;
  created_at: string;
}

/** ******************************************
 * Customer Support
 ******************************************* */

export enum MessageSenderType {
  HUMAN = 1,
  MACHINE = 2,
}

export interface Message {
  id: number;
  author?: number;
  content: string;
  sender_type: MessageSenderType;
  created_at: string;
}

export interface MessageList {
  messages: Message[];
  users: User[];
}

/** ******************************************
 * Radiologist Schedules
 ******************************************* */

export enum Weekday {
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
  SUN = 7,
}

export interface RadiologistSchedule {
  id: string;
  radiologist: string;
  start_time: string;
  end_time: string;
  weekday: Weekday;
  max_scans: number;
  is_backup: boolean;
}

export type RadiologistWeeklySchedule = RadiologistSchedule[];

export type RadiologistScheduleInput = Omit<RadiologistSchedule, 'id'>;

/** ******************************************
 * Report Text Expansion
 ******************************************* */

export interface ReportExpansion {
  id: number;
  keyword: string;
  text: string;
}

/** ******************************************
 * Customer Support
 ******************************************* */

export interface UserSessions {
  [key: string]: string[];
}

/** ******************************************
 * TICKET RELATED
 ******************************************* */

export interface TicketComment {
  id: number;
  user: number;
  ticket: number;
  comment: string;
  created_at: string;
}

export enum TicketActivityType {
  CREATE = 0,
  UPDATE = 1,
  ADD_ATTACHMENT = 2,
  ADD_COMMENT = 3,
  DELETE = 4,
}

export const TicketActivityTypeDisplayMap: Record<TicketActivityType, string> = {
  [TicketActivityType.CREATE]: 'Create',
  [TicketActivityType.UPDATE]: 'Update',
  [TicketActivityType.ADD_ATTACHMENT]: 'Add Attachment',
  [TicketActivityType.ADD_COMMENT]: 'Add Comment',
  [TicketActivityType.DELETE]: 'Delete',
};

export interface TicketActivity {
  id: number;
  ticket: number;
  user: number;
  type: TicketActivityType;
  change: string;
  created_at: string;
}

export interface TicketAttachment extends Blob {
  ticket: number;
}

export enum TicketStatus {
  OPEN = 0,
  INPROGRESS = 1,
  BLOCKED = 2,
  DONE = 3,
}

export const TicketStatusDisplayMap: Record<TicketStatus, string> = {
  [TicketStatus.OPEN]: 'Open',
  [TicketStatus.INPROGRESS]: 'In Progress',
  [TicketStatus.BLOCKED]: 'Blocked',
  [TicketStatus.DONE]: 'Done',
};

export enum TicketType {
  REPORT_ISSUE = 0,
  SOFTWARE_ISSUE = 1,
  BILLING_ISSUE = 2,
}

export const TicketTypeDisplayMap: Record<TicketType, string> = {
  [TicketType.REPORT_ISSUE]: 'Report Issue',
  [TicketType.SOFTWARE_ISSUE]: 'Software Issue',
  [TicketType.BILLING_ISSUE]: 'Billing Issue',
};

export enum TicketCustomerType {
  CUSTOMER = 0,
  CUSTOMER_CENTER = 1,
}

export interface IvrsConversation {
  conversation_id: string;
  call_direction: string;
  call_from?: string;
  call_to?: string;
  status: string;
  customer_call_uuid?: string;
  agent_call_uuid: string[];

  // Start time of call (ts of first event) in iso format string
  call_start_ts: string;
  // Updated time of call (ts of last event) in iso format string
  last_update_ts: string;

  // Teleci user id of agent
  telecmi_user_id?: string;
  // Telecmi Team id (only for incoming calls)
  telecmi_team_id?: string;
  // Telecmi request id (only for outgoing calls)
  telecmi_request_id?: string;

  // Telefi User id of agent
  agent_user_id?: string;

  // Phone no of customer (would be either call_from or call_to number)
  customer_phone?: string;
  // User id of customer in Telefi to which calls is made / coming from
  customer_id?: number;
  // Name / Username of customer in Telefi to which calls is made / coming from
  customer_name?: string;
  // Type of customer to which call is made / coming from
  customer_type?: TicketCustomerType;
  // Center id of Customer if applicable
  center_id?: number;

  duration: number;
}

interface IVRSTicketMeta {
  type: 'IVRS';
  payload: IvrsConversation;
}

export type TicketCreateMeta = IVRSTicketMeta;

export interface Ticket {
  id: number;
  create_source: string;
  created_by: string;
  create_meta: TicketCreateMeta;
  type: TicketType;
  status: TicketStatus;
  assignee: number;
  customer?: number;
  customer_name?: string;
  customer_type?: TicketCustomerType;
  title: string;
  desc?: string;
  created_at: string;
  modified_at: string;
}

export interface TicketDetail extends Omit<Ticket, 'assignee'> {
  assignee?: User;
  attachments: string[];
  comments: TicketComment[];
  activity: TicketActivity[];
  users: Record<string, User>;
}

export type TicketUpdateInput = Partial<
  Pick<TicketDetail, 'type' | 'status' | 'assignee' | 'desc'>
>;

/** ******************************************
 * HEALTHCHECK RELATED
 ******************************************* */

export interface HealthCheckData {
  active_call?: IvrsConversation;
}
