import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { environment } from './common/environment';
import reportWebVitals from './reportWebVitals';
import { AuthContextContainer } from './services/auth/AuthContext';
import { registerServiceWorker } from './services/service-worker/service-worker-service';

const LightTheme = React.lazy(() => import('./components/layouts/theme/LightTheme'));
const DarkTheme = React.lazy(() => import('./components/layouts/theme/DarkTheme'));

const ThemedApp = () => {
  const { theme } = environment;

  useEffect(() => {
    // Required for modal and other things which are outside the
    // App comopnent in the DOM heirarchy
    document.body.classList.add(`theme-${theme}`);
  }, []);

  return (
    <div className={`root theme-${theme}`}>
      <React.Suspense fallback={<></>}>
        {theme === 'light' && <LightTheme />}
        {theme === 'dark' && <DarkTheme />}
      </React.Suspense>
    </div>
  );
};

/**
 * REACT QUERY PROVIDER
 */

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <AuthContextContainer>
      <Router>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <ThemedApp />
          </QueryClientProvider>
        </RecoilRoot>
      </Router>
    </AuthContextContainer>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// register the service worker
registerServiceWorker();
